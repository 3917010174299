<template>
  <div>
    <create-form
      type="create"
      @onsave="onCreate"
    />
  </div>
</template>
<script>
export default {
  components: {
    createForm: () => import("../utils/Form.vue"),
  },
  data() {
    return {};
  },
  methods: {
    async onCreate(params) {
      const payload = { ...params }; //clone
      payload.is_active = payload.is_active == "active" ? "1" : "0";
      payload.return_to_depot = payload.return_to_depot == true ? true : false;
      payload.optimize_sequence =
        payload.optimize_sequence == true ? true : false;

      this.$store
        .dispatch("routeListing/add", payload)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
  },
};
</script>
